import { Injectable, Inject } from "@angular/core";
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { HttpHeaders } from "@angular/common/http";
import { ApiService } from "./api.service";
import { Store } from "@ngrx/store";
import Auth0Lock from "auth0-lock";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ROUTES, PROXY_HEADER } from "../constants/constants";
import { Logout } from "../../actions";
import { LocalStorageService } from "./local-storage.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  returnUrl: string;

  // private _Auth0 = new auth0.WebAuth({
  //   clientID: environment.auth.clientID,
  //   domain: environment.auth.domain,
  //   responseType: "token",
  //   redirectUri: environment.auth.redirect,
  //   scope: environment.auth.scope
  // });
  // private auth0 = new Auth0Lock(
  //   environment.auth.clientID,
  //   environment.auth.domain,
  //   {
  //     oidcConformant: true,
  //     autoclose: true,
  //     rememberLastLogin: false,
  //     allowSignUp: false,
  //     allowedConnections: ["Username-Password-Authentication"],
  //     auth: {
  //       autoParseHash: false,
  //       // redirectUrl: environment.production
  //       //   ? "https://" + window.location.hostname + "/callback"
  //       //   : "http://" + window.location.hostname + "4200/callback",
  //       redirectUrl: environment.auth.redirect,
  //       responseType: "token id_token",
  //       audience: environment.auth.audience,
  //       params: {
  //         scope: "openid profile email app_metadata"
  //       }
  //     },
  //     theme: {
  //       logo: "assets/images/logo2.png",
  //       primaryColor: "#31324F",
  //       displayName: "Sign in"
  //     },
  //     languageDictionary: {
  //       emailInputPlaceholder: "Email",
  //       title: "WMS",
  //       passwordInputPlaceholder: "Password"
  //     }
  //   }
  // );

  // Track whether or not to renew token
  private _authFlag = "isLoggedIn";
  // Authentication navigation
  private _onAuthSuccessUrl;
  private _onAuthFailureUrl = "";
  private _logoutUrl = "http://localhost:4200";
  private _expiresAt: number;
  public headers = new HttpHeaders();
  // Remove VERIFY-OTP IF PAGE INJECTED INSIDE SHOPIFY
  isFromShopify: boolean;
  /**
   * Creates an instance of AuthService
   * @param platformId - platformId
   * @param api - HTTP service to call the APIS
   * @param store - Store
   * */
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private api: ApiService,
    private store: Store<{ auth }>,
    private router: Router
  ) {
    // this._onAuthSuccessUrl =
    //   (window.location.hostname !== "warehouse.auperator.co" &&
    //     window.location.hostname !== "warehouse.eshopbox.com")
    //     ? ROUTES.WHOLESALE
    //     : ROUTES.INBOUND;
    //     console.log(this._onAuthSuccessUrl);
  }

  public handleAuthenticationWithHash() {
    //: Promise<any>
  }

  /*For generating token on getting encoded key in url*/
  public generateTokenWithEncodedKey(encodedKey, queryParams) {
    if(queryParams.action === 'ua'){
      localStorage.setItem("action", queryParams.action);
      localStorage.setItem('shipmentId',queryParams.shipmentId)
    }else{
      localStorage.removeItem("action")
      localStorage.removeItem("shipmentId")
    }
    setTimeout(() => {
      const decodedString = encodedKey.split("##");
      if (queryParams.isaction == "true") {
        localStorage.setItem(
          "isReAttemptAction",
          queryParams.isReAttemptAction
        ),
          this.router.navigate([
            ROUTES.ORDER_FAILED_DELIVERY,
            decodedString[0],
            queryParams.orderItemId
          ]);
      }
    }, 2000);

    if (!LocalStorageService.get("token")) {
      const decodedString = encodedKey.split("##");

      if (queryParams.key === "redirect") {
        const params = { mobile: decodedString[2] };
        let AuthorizationToken = "Bearer " + queryParams.authToken;
        const headers = {
          Authorization: AuthorizationToken,
          accountSlug: queryParams.proxyHost,
          ProxyHost: "http://test_test_ajeet98.com"
        };
        localStorage.setItem("customerOrderNumber", decodedString[0]);
        return this.api
          .post(environment.TOKEN_GENERATE_URL, params, headers)
          .subscribe(res => {
            if (res) {
              console.log("queryParams savan", queryParams,res);

              this.setAuthToken(res);
              this.setTokenType(res);
              if (queryParams.isDeliveryFailed == "true")
                localStorage.setItem(
                  "isReAttemptAction",
                  queryParams.isReAttemptAction
                ),
                  this.router.navigate([
                    ROUTES.ORDER_FAILED_DELIVERY,
                    decodedString[0],
                    queryParams.orderItemId
                  ]);
              else if (queryParams.isCancelPickup == "true")
                this.router.navigate([
                  ROUTES.ORDER_RETURN_CANCELLATION,
                  decodedString[0],
                  queryParams.orderItemId
                ]);
              else if (queryParams.isCancelItem == "true")
                this.router.navigate([
                  ROUTES.ORDER_CANCEL,
                  decodedString[0],
                  queryParams.orderItemId
                ]);
              else if (queryParams.isReturnInitiated == "true")
                this.router.navigate([
                  ROUTES.ORDER_REFUND_EXCHANGE,
                  decodedString[0],
                  queryParams.orderItemId
                ]);
              // this.router.navigate([ROUTES.ORDER_RETURN, decodedString[0], queryParams.orderItemId]);
              else
                this.router.navigate([
                  ROUTES.ORDER_PLACED,
                  decodedString[0],
                  queryParams.orderItemId
                ]);
            }
          });
      } else {
        if (decodedString[2]) {
          const params = { mobile: decodedString[2] };
          return this.api
            .post(environment.TOKEN_GENERATE_URL, params, "")
            .subscribe(res => {
              if (res) {
                // console.log("savan surani",res)
                this.setAuthToken(res);
                this.setTokenType(res);
                this.router.navigate([
                  ROUTES.ORDER_PLACED,
                  decodedString[0],
                  decodedString[1]
                ]);
                // return res;
              }
            });
        }
      }
    }
  }

  /*Send Otp*/
  onSendOtp(mobile) {
    const params = { mobile: mobile };
    return this.api.post(environment.LOGIN_URL, params, "").pipe(
      map(res => {
        this.setAuthToken(res);
        return res;
      })
    );
  }

  /*Login With Mobile*/
  onLogin(otp) {
    const params = { otp: otp };
    return this.api
      .post(environment.API_URL + "auth/otp/validate", params, "")
      .pipe(
        map(res => {
          this.setAuthToken(res);
          return res;
        })
      );
  }

  getAuthToken() {
    if (isPlatformBrowser(this.platformId)) {
      return LocalStorageService.get("token");
    } else {
      return null;
    }
  }

  setAuthToken(tokenData) {
    return LocalStorageService.set("token", tokenData.accessToken);
  }

  isLoggedIn() {
    if (this.getAuthToken() == null) {
      return false;
    }
    return true;
  }

  /**
   * Call the Login API and store the user in localStorage.
   * @param email - email of the user;
   * @param password - password of the user;
   * @returns user - User from the response of the API;
   */
  // login({ username, password }) {
  //   const params = { data: { username: username, password: password } };
  //   return this.api.post(apiUrl.login, params).pipe(
  //     map(user => {
  //       this.setAuthToken(JSON.stringify(user), "token");
  //       return user;
  //     })
  //   );
  // }

  get authSuccessUrl(): string {
    return this._onAuthSuccessUrl;
  }

  get authFailureUrl(): string {
    return this._onAuthFailureUrl;
  }

  get authenticated(): boolean {
    return JSON.parse(localStorage.getItem(this._authFlag));
  }

  resetAuthFlag() {
    localStorage.removeItem(this._authFlag);
  }

  login() {
    // this.auth0.show();
  }

  setAuth(authResult) {
    this._expiresAt = authResult.expiresIn * 1000 + Date.now();
    // Set flag in local storage stating this app is logged in
    localStorage.setItem(this._authFlag, JSON.stringify(true));
    localStorage.setItem("token", authResult.accessToken);
  }

  logout() {
    // Set authentication status flag in local storage to false
    localStorage.clear();
    this.store.dispatch(new Logout());
    // This does a refresh and redirects back to homepage
    // Make sure you have the logout URL in your Auth0
    // Dashboard Application settings in Allowed Logout URLs
    // this.router.navigate([ROUTES.LOGIN]);
  }

  setTokenType(tokenData) {
    return LocalStorageService.set("tokenType", tokenData.type);
  }

  getTokenType() {
    if (isPlatformBrowser(this.platformId)) {
      return LocalStorageService.get("tokenType");
    } else {
      return null;
    }
  }
}
